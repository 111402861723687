<template>
  <div class="home">
    <div class="head-box">
      <div class="head">
        <div class="logo"><img src="../assets/logo.png" alt="" /></div>
        <div class="name">
          <div class="name-ch">深圳市光明区行政服务大厅</div>
          <div class="name-en">Shenzhen Guangming District Administration Service Hall</div>
        </div>
        <div class="slogan"><img src="../assets/slogan.png" alt="" /></div>
      </div>
    </div>
    <div class="dialog-box">
      <div v-for="(item, index) in dialogArray" :key="index">
        <div class="left-dialog" v-if="item.clientType == 'slave'">
          <div class="left-box">
            <div class="head-img">
              <img :src=item.iconUrl alt="" />
              <span>{{item.title}}</span>
            </div>
            <div class="left"><img src="../assets/left.png" alt="" /></div>
            <div class="text-box">
              <span class="mb-8">{{item.textual}}</span>
              <span>{{item.translation}}</span>
            </div>
          </div>
        </div>
        <div class="right-dislog" v-if="item.clientType == 'master'">
          <div class="right-box">
            <div class="text-box">
              <span class="mb-8">{{item.textual}}</span>
              <span>{{item.translation}}</span>
            </div>
            <div class="right"><img src="../assets/right.png" alt="" /></div>
            <div class="head-img">
              <img :src=item.iconUrl alt="" />
              <span>{{item.title}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="no-ws" v-if="!ifID">
      <img src="../assets/no_id.png" alt="">
      <span>投屏码错误或已在其他地方打开，请确认浏览器地址栏中输入的投屏码是否正确并刷新。</span>
    </div>
  </div>
</template>

<script>
import language from "./language";
export default {
  data() {
    return {
      websock: null, // ws长连接对象
      websockectStatus: false,
      screenCode: "",
      dataArray: {},
      dialogArray: [],
      ifID: true,
      wsSettimeout: "",
    };
  },
  created() {
    if (this.$route.query.id) {
      this.screenCode = this.$route.query.id;
      this.ifID = true;
    } else {
      this.ifID = false;
    }
    this.initWebSocket();
  },
  // destroyed() {
  //   this.websock.close(); //离开路由之后断开websocket连接
  // },
  methods: {
    initWebSocket() {
      console.log("开始连接", new Date());
      //初始化weosocket
      var timestamp = new Date().getTime();
      let wsuri =
        // "ws://testhktransws.yunjivip.cn?screenCode=" +
        "wss://bh5.yunjivip.cn/ws?screenCode=" +
        this.screenCode +
        "&imei=" +
        this.screenCode +
        "&sdkType=h5&timestamp=" +
        timestamp.toString();
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },
    hearbeatSend() {
      this.wsSettimeout = setTimeout(() => {
        let msg = {
          code: 200,
          action: "ping",
          data: { data: "ping" },
          isEncrypt: false,
          returnDataType: "url",
        };
        console.log("发送心跳", new Date());
        this.websocketsend(JSON.stringify(msg));
        this.hearbeatSend();
      }, 30000);
    },
    websocketonopen(e) {
      console.log("连接成功", e.currentTarget.readyState);
      if (e.currentTarget.readyState == 1) this.hearbeatSend();
    },
    websocketonerror(e) {
      console.log("连接失败", e);
    },
    websocketonmessage(e) {
      //数据接收
      let redata = JSON.parse(e.data);
      console.log("数据接收", redata.code);
      if (redata.action == "TS" || redata.action == "quick") {
        this.dialogArray.push({
          iconUrl: require("../assets/" + language[redata.from].name),
          title: language[redata.from].title,
          textual: redata.source,
          translation: redata.data,
          clientType: redata.clientType,
        });
      } else if (redata.action == "ATS") {
        if (redata.step == "ASR") {
          this.dataArray = {
            iconUrl: require("../assets/" + language[redata.from].name),
            title: language[redata.from].title,
            textual: redata.data,
            clientType: redata.clientType,
          };
        } else if (redata.step == "TT") {
          this.dataArray.translation = redata.data;
        }
        if (this.dataArray.translation) {
          this.dialogArray.push(this.dataArray);
          this.dataArray = {};
        }
      }
      if (redata.code == 303) this.dialogArray = [];
      if (
        redata.code == 203 ||
        redata.code == 204 ||
        redata.code == 400 ||
        redata.code == 404 ||
        redata.code == 4003
      ) {
        clearTimeout(this.wsSettimeout);
        this.ifID = false;
        this.dialogArray = [];
        this.websockectStatus = true;
      }
    },
    websocketsend(Data) {
      //数据发送
      // console.log("发送数据", Data);
      this.websock.send(Data);
    },
    websocketclose(e) {
      // 关闭
      console.log("断开连接", e);
      clearTimeout(this.wsSettimeout);
      // 断连后重新连接
      if (!this.websockectStatus) {
        setTimeout(() => {
          this.initWebSocket();
        }, 5000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.no-ws {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 30;
  user-select: none;
  img {
    width: 312px;
    height: 202px;
    display: block;
    margin-bottom: 36px;
  }
  span {
    font-size: 20px;
    color: #333333;
  }
}
.dialog-box {
  padding: 40px 32px 0px 32px;
  .left-dialog {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 40px;
  }
  .right-dislog {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 40px;
  }
}
.right-box {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  .text-box {
    max-width: 700px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: #0098d9;
    border-radius: 10px;
    padding: 12px 50px 20px 16px;
    span {
      font-size: 20px;
      color: #ffffff;
    }
  }
  .right {
    margin-top: 18px;
    margin-left: -2px;
    img {
      width: 10px;
      height: 18px;
      display: block;
    }
  }
  .head-img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 7px;
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: block;
      margin-bottom: 8px;
    }
    span {
      font-size: 16px;
      color: #3d3d3d;
    }
  }
}
.left-box {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  .text-box {
    max-width: 700px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: #f5f5f5;
    border: 0 solid #d8d8d8;
    border-radius: 10px;
    padding: 12px 50px 20px 16px;
    span {
      font-size: 20px;
      color: #333333;
    }
  }
  .left {
    margin-top: 18px;
    margin-right: -2px;
    img {
      width: 10px;
      height: 18px;
      display: block;
    }
  }
  .head-img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 7px;
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: block;
      margin-bottom: 8px;
    }
    span {
      font-size: 16px;
      color: #3d3d3d;
    }
  }
}
.dialog-box {
  position: fixed;
  bottom: 0;
  left: calc(50vw - 600px);
  width: 1136px;
  min-height: calc(100vh - 160px);
  background: #ffffff;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  z-index: 10;
  user-select: none;
}
.head-box {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  background: #0098d9;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  user-select: none;
  z-index: 20;
  transform: translate3d(0, 0, 0); //相邻元素box-shadow 被后边元素挡住
  .head {
    width: 1200px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .logo {
    margin-right: 24px;
    img {
      width: 110px;
      height: 110px;
      display: block;
    }
  }
  .name {
    margin-right: 33px;
    .name-ch {
      font-weight: Bold;
      font-size: 44px;
      color: #ffffff;
      margin-bottom: 6px;
    }
    .name-en {
      font-size: 19px;
      color: #ffffff;
    }
  }
  .slogan {
    img {
      width: 364px;
      height: 37px;
      display: block;
    }
  }
}
.mb-8 {
  margin-bottom: 8px;
}
</style>
