var language = {
  1: { name: "ic_circle_za_nl.png", title: "南非荷兰语" },
  3: { name: "ic_circle_hy_am.png", title: "亚美尼亚语" },
  5: { name: "ic_circle_id_id.png", title: "印度尼西亚语" },
  6: { name: "ic_circle_ms_my.png", title: "马来语" },
  7: { name: "ic_circle_bn_bd.png", title: "孟加拉语(孟加拉)" },
  8: { name: "ic_circle_bn_in.png", title: "孟加拉语(印度)" },
  9: { name: "ic_circle_ca_es.png", title: "加泰罗尼亚语" },
  10: { name: "ic_circle_cs_cz.png", title: "捷克语" },
  11: { name: "ic_circle_da_dk.png", title: "丹麦语" },
  12: { name: "ic_circle_de_de.png", title: "德语" },
  13: { name: "ic_circle_en_au.png", title: "英语(澳大利亚)" },
  14: { name: "ic_circle_en_ca.png", title: "英语(加拿大)" },
  15: { name: "ic_circle_en_gh.png", title: "英语(加纳)" },
  16: { name: "ic_circle_en_gb.png", title: "英语(英国)" },
  17: { name: "ic_circle_en_in.png", title: "英语(印度)" },
  18: { name: "ic_circle_en_ie.png", title: "英语(爱尔兰)" },
  19: { name: "ic_circle_en_ke.png", title: "英语(肯尼亚)" },
  20: { name: "ic_circle_en_nz.png", title: "英语(新西兰)" },
  21: { name: "ic_circle_en_ng.png", title: "英语(尼日利亚)" },
  22: { name: "ic_circle_en_ph.png", title: "英语(菲律宾)" },
  23: { name: "ic_circle_en_sg.png", title: "英语(新加坡)" },
  24: { name: "ic_circle_en_za.png", title: "英语(南非)" },
  25: { name: "ic_circle_en_tz.png", title: "英语(坦桑尼亚)" },
  26: { name: "ic_circle_en_us.png", title: "英语(美国)" },
  27: { name: "ic_circle_es_ar.png", title: "西班牙语(阿根廷)" },
  28: { name: "ic_circle_es_bo.png", title: "西班牙语(玻利维亚)" },
  29: { name: "ic_circle_es_cl.png", title: "西班牙语(智利)" },
  30: { name: "ic_circle_es_co.png", title: "西班牙语(哥伦比亚)" },
  31: { name: "ic_circle_es_cr.png", title: "西班牙语(哥斯达黎加)" },
  32: { name: "ic_circle_es_ec.png", title: "西班牙语(厄瓜多尔)" },
  33: { name: "ic_circle_es_sv.png", title: "西班牙语(萨尔瓦多)" },
  34: { name: "ic_circle_es_es.png", title: "西班牙语(西班牙)" },
  35: { name: "ic_circle_es_us.png", title: "西班牙语(美国)" },
  36: { name: "ic_circle_es_gt.png", title: "西班牙语(危地马拉)" },
  37: { name: "ic_circle_es_hn.png", title: "西班牙语(洪都拉斯)" },
  38: { name: "ic_circle_es_mx.png", title: "西班牙语(墨西哥)" },
  39: { name: "ic_circle_es_ni.png", title: "西班牙语(尼加拉瓜)" },
  40: { name: "ic_circle_es_pa.png", title: "西班牙语(巴拿马)" },
  41: { name: "ic_circle_es_py.png", title: "西班牙语(巴拉圭)" },
  42: { name: "ic_circle_es_pe.png", title: "西班牙语(秘鲁)" },
  43: { name: "ic_circle_es_pr.png", title: "西班牙语(波多黎各)" },
  44: { name: "ic_circle_es_do.png", title: "西班牙语(多米尼加共和国)" },
  45: { name: "ic_circle_es_uy.png", title: "西班牙语(乌拉圭)" },
  46: { name: "ic_circle_es_ve.png", title: "西班牙语(委内瑞拉)" },
  49: { name: "ic_circle_fr_ca.png", title: "法语(加拿大)" },
  50: { name: "ic_circle_fr_fr.png", title: "法语(法国)" },
  54: { name: "ic_circle_hr_hr.png", title: "克罗地亚语" },
  56: { name: "ic_circle_is_is.png", title: "冰岛语" },
  57: { name: "ic_circle_it_it.png", title: "意大利语" },
  60: { name: "ic_circle_ln_km.png", title: "高棉语(柬埔寨)" },
  62: { name: "ic_circle_lv_lv.png", title: "拉脱维亚语" },
  64: { name: "ic_circle_hu_hu.png", title: "匈牙利语" },
  67: { name: "ic_circle_nl_nl.png", title: "荷兰语" },
  68: { name: "ic_circle_ne_np.png", title: "尼泊尔语" },
  69: { name: "ic_circle_nb_no.png", title: "挪威语" },
  70: { name: "ic_circle_ln_nw.png", title: "博克马尔挪威语(挪威)" },
  71: { name: "ic_circle_pl_pl.png", title: "波兰语" },
  72: { name: "ic_circle_pt_br.png", title: "葡萄牙语(巴西)" },
  73: { name: "ic_circle_pt_pt.png", title: "葡萄牙语(葡萄牙)" },
  74: { name: "ic_circle_ro_ro.png", title: "罗马尼亚语" },
  75: { name: "ic_circle_si_lk.png", title: "僧伽罗语" },
  76: { name: "ic_circle_sk_sk.png", title: "斯洛伐克语" },
  77: { name: "ic_circle_sl_sl.png", title: "斯洛文尼亚语" },
  79: { name: "ic_circle_sw_tz.png", title: "斯瓦希里语(坦桑尼亚)" },
  80: { name: "ic_circle_sw_ke.png", title: "斯瓦希里语(肯尼亚)" },
  81: { name: "ic_circle_fi_fi.png", title: "芬兰语" },
  82: { name: "ic_circle_sv_se.png", title: "瑞典语" },
  83: { name: "ic_circle_ta_in.png", title: "泰米尔语(印度)" },
  84: { name: "ic_circle_ta_sg.png", title: "泰米尔语(新加坡)" },
  85: { name: "ic_circle_ta_lk.png", title: "泰米尔语(斯里兰卡)" },
  86: { name: "ic_circle_ta_my.png", title: "泰米尔语(马来西亚)" },
  87: { name: "ic_circle_te_in.png", title: "泰卢固语(印度)" },
  88: { name: "ic_circle_vi_vn.png", title: "越南语" },
  89: { name: "ic_circle_tr_tr.png", title: "土耳其语" },
  90: { name: "ic_circle_ur_pk.png", title: "乌尔都语(巴基斯坦)" },
  91: { name: "ic_circle_ur_in.png", title: "乌尔都语(印度)" },
  92: { name: "ic_circle_el_gr.png", title: "希腊语" },
  93: { name: "ic_circle_bg_bg.png", title: "保加利亚语" },
  94: { name: "ic_circle_ru_ru.png", title: "俄语" },
  95: { name: "ic_circle_sr_rs.png", title: "塞尔维亚语" },
  97: { name: "ic_circle_uk_ua.png", title: "乌克兰语" },
  98: { name: "ic_circle_he_il.png", title: "希伯来语" },
  99: { name: "ic_circle_ar_il.png", title: "阿拉伯语(以色列)" },
  100: { name: "ic_circle_ar_jo.png", title: "阿拉伯语(约旦)" },
  101: { name: "ic_circle_ar_ae.png", title: "阿拉伯语(阿拉伯联合酋长国)" },
  102: { name: "ic_circle_ar_bh.png", title: "阿拉伯语(巴林)" },
  103: { name: "ic_circle_ar_dz.png", title: "阿拉伯语(阿尔及利亚)" },
  104: { name: "ic_circle_ar_sa.png", title: "阿拉伯语(沙特阿拉伯)" },
  105: { name: "ic_circle_ar_iq.png", title: "阿拉伯语(伊拉克)" },
  106: { name: "ic_circle_ar_kw.png", title: "阿拉伯语(科威特)" },
  107: { name: "ic_circle_ar_ma.png", title: "阿拉伯语(摩洛哥)" },
  108: { name: "ic_circle_ar_tn.png", title: "阿拉伯语(突尼斯)" },
  109: { name: "ic_circle_ar_om.png", title: "阿拉伯语(阿曼)" },
  110: { name: "ic_circle_ar_ps.png", title: "阿拉伯语(巴勒斯坦国)" },
  111: { name: "ic_circle_ar_qa.png", title: "阿拉伯语(卡塔尔)" },
  112: { name: "ic_circle_ar_lb.png", title: "阿拉伯语(黎巴嫩)" },
  113: { name: "ic_circle_ar_eg.png", title: "阿拉伯语(埃及)" },
  115: { name: "ic_circle_hi_in.png", title: "印地语(印度)" },
  116: { name: "ic_circle_th_th.png", title: "泰语" },
  117: { name: "ic_circle_ko_kr.png", title: "韩语" },
  118: { name: "ic_circle_zh_tw.png", title: "中文繁体" },
  119: { name: "ic_circle_zh_hk.png", title: "粤语" },
  120: { name: "ic_circle_ja_jp.png", title: "日语" },
  122: { name: "ic_circle_zh_cn.png", title: "中文简体" },
  131: { name: "ic_circle_et_et.png", title: "爱沙尼亚语" },
  140: { name: "ic_circle_tl_tl.png", title: "塔加路语(菲律宾语)" },
  153: { name: "ic_circle_mm_my.png", title: "缅甸语" },
};
export default language;
